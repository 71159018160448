<template>
  <input
    :disabled="readonly"
    v-model="checked"
    type="checkbox"
    placeholder="temp"
    @input="onCheck(($event.target as HTMLInputElement).checked)"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Checkbox",
  props: {
    readonly: { required: false, type: Boolean },
    value: { required: true, type: Boolean },
  },
  emits: ["newValue"],
  setup(props, { emit }) {
    let checked = ref(props.value);

    function onCheck(value: boolean) {
      checked.value = value;
      emit("newValue", value);
    }

    return { checked, onCheck };
  },
});
</script>

<style scoped lang="scss" src="./Checkbox.scss"></style>
