import { defineStore } from "pinia";

import { InputFieldType, InputType } from "../components/basicParts/form/Form.vue";
import httpCommon from "../plugins/http-common";
import { AnyDict, JsonTable } from "../types/general";
import { Permission } from "./auth";

interface State {
  currentRobotMap: JsonTable;
}

export const useMapStore = defineStore("map", {
  state: (): State => {
    return {
      currentRobotMap: {},
    };
  },
  getters: {
    mapInputs: () => [
      [
        {
          field: "value",
          fieldType: InputFieldType.object,
          dontShowTitle: true,
          type: InputType.jsonbox,
          readLevel: Permission.User,
          editLevel: Permission.User,
        },
      ],
    ],
  },
  actions: {
    async getRobotMap(robot_id: number) {
      const res = await httpCommon.get("maps/ui/active/" + robot_id);
      this.currentRobotMap = res.data;
      return res.data;
    },
    async patchRobotMap(robot_id: number, map: AnyDict) {
      const res = await httpCommon.patch("maps/ui/active/" + robot_id, map);
      return res.data;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
