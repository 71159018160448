<template>
  <textarea
    v-if="textarea"
    v-model="localValue"
    :disabled="readonly"
    :placeholder="translatedPlaceholder"
    @input="$emit('newValue', ($event.target as HTMLInputElement).value)"
    @focusout="$emit('format', ($event.target as HTMLInputElement).value)"
  />
  <input
    v-else
    v-model="localValue"
    :disabled="readonly"
    :placeholder="translatedPlaceholder"
    @input="$emit('newValue', ($event.target as HTMLInputElement).value)"
    @focusout="$emit('format', ($event.target as HTMLInputElement).value)"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Textbox",
  props: {
    textarea: { required: false, type: Boolean, default: false },
    placeholder: { required: false, type: String },
    readonly: { required: false, type: Boolean },
    value: { required: false, type: [String, Array, Object, Number] },
  },
  setup(props) {
    const { t } = useI18n();
    let localValue = "";

    const translatedPlaceholder = t(props.placeholder);

    function setLocalValue() {
      if (props.value) {
        localValue = props.value as string;
      } else {
        localValue = "";
      }
    }

    setLocalValue();

    return { localValue, translatedPlaceholder };
  },
});
</script>

<style scoped lang="scss" src="./Textbox.scss"></style>
