import { AxiosResponse } from "axios";
import { defineStore } from "pinia";

import httpCommon from "../plugins/http-common";
import { Client } from "../types/general";
import { useRobotStore } from "./robot";

interface State {
  clients: Client[];
  currentClient: Client | null;
  clientSelectionRedirect: boolean;
}

export const useClientStore = defineStore("client", {
  state: (): State => {
    return {
      clients: [],
      currentClient: null,
      clientSelectionRedirect: false,
    };
  },
  getters: {},
  actions: {
    toggleCurrentClient(client: Client, selected: boolean) {
      const robotStore = useRobotStore();
      robotStore.toggleCurrentRobot(null, false);
      this.currentClient = selected ? client : null;
    },
    validateResponse(res: AxiosResponse) {
      if (res && !res.data.error) {
        return res.data as Client;
      }
      return {};
    },
    async fetchClients() {
      const res = await httpCommon.get("clients/");
      if (res && !res.data.error) {
        this.clients = res.data;
        return res.data;
      }
      return {};
    },
    async fetchClient(id: number) {
      const res = await httpCommon.get("clients/" + id);
      return this.validateResponse(res);
    },
    async patchClient(client: Client) {
      const res = await httpCommon.patch("clients/", client);
      return this.validateResponse(res);
    },
    async postClient(client: Client) {
      const res = await httpCommon.post("clients/", client);
      return this.validateResponse(res);
    },
    async deleteClient(id: number) {
      await httpCommon.delete("clients/" + id);
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
