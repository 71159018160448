<template>
  <div class="container">
    <div class="mainContainer">
      <svg preserveAspectRatio="xMaxYMax meet" class="SSLogoIcon"></svg>
      <div class="topButtonContainer">
        <div v-for="button in topButtons" :key="JSON.stringify(button)">
          <NavMenuButton v-if="permission && permission >= (button.minPermission as Permission)" :buttonInfo="button" />
        </div>
      </div>
      <div class="buttonContainer">
        <div v-for="button in bottomButtons" :key="JSON.stringify(button)">
          <NavMenuButton v-if="permission && permission >= (button.minPermission as Permission)" :buttonInfo="button" />
        </div>
      </div>
    </div>
    <div class="drawer">
      <router-link class="drawerLink" to="/error">
        <svg preserveAspectRatio="xMaxYMax meet" class="userIcon"></svg>
        <div class="drawerTextContainer">
          <a class="nameText">{{ fullname }}</a>
          <a class="emailText">{{ email }}</a>
        </div>
      </router-link>
      <button class="logoutButton" @click="loggingOut = true">
        <svg preserveAspectRatio="xMaxYMax meet" class="logoutIcon"></svg>
      </button>
    </div>
    <ModalWindow v-if="loggingOut">
      <Popup
        text="logoutConfirmation"
        :color="PopupColors.yellow"
        :buttons="PopupButtons.confirmCancel"
        :icon="PopupIcons.Warning"
        @confirm="logout"
        @cancel="loggingOut = false"
      ></Popup>
    </ModalWindow>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";

import router from "../../router";
import { Permission, useAuthStore } from "../../store/auth";
import { NavMenuButtonInfo } from "../../types/general";
import NavMenuButton from "../basicParts/buttons/navMenuButton/NavMenuButton.vue";
import ModalWindow from "../basicParts/modalWindow/ModalWindow.vue";
import Popup from "../basicParts/modalWindow/popup/Popup.vue";
import { PopupButtons, PopupColors, PopupIcons } from "../basicParts/modalWindow/popup/Popup.vue";

export default defineComponent({
  name: "NavMenu",
  components: {
    NavMenuButton,
    ModalWindow,
    Popup,
  },
  setup() {
    const store = useAuthStore();

    const permission = computed(() => store.permission);
    const fullname = computed(() => store.fullname);
    const email = computed(() => store.email);

    const loggingOut = ref(false);

    function logout() {
      store.logout();
      router.push("/login");
    }

    const lockerButtons: NavMenuButtonInfo = {
      path: "/lockers",
      subButtons: [
        {
          path: "/lockers/configs",
        },
      ],
    };

    const robotButtons: NavMenuButtonInfo = {
      path: "/robots",
      subButtons: [
        {
          path: "/robots/schedule",
          subButtons: [
            {
              path: "/robots/schedule/routes",
            },
            {
              path: "/robots/schedule/tasks",
            },
            {
              path: "/robots/schedule/actions",
            },
          ],
        },
        {
          path: "/robots/configs",
        },
        {
          path: "/robots/features",
        },
        {
          path: "/robots/maps",
        },
        {
          path: "/robots/states",
        },
      ],
    };

    const topButtons: NavMenuButtonInfo[] = [
      {
        path: "/home",
      },
      lockerButtons,
      robotButtons,
    ];

    const bottomButtons: NavMenuButtonInfo[] = [
      {
        path: "/clients",
      },
      {
        path: "/users",
      },
    ];

    function addPathMetaInfo(buttons: Array<NavMenuButtonInfo>) {
      buttons.forEach((btn) => {
        const buttonRoute = router.getRoutes().find((route) => route.path === btn.path);
        btn.title = !btn.title && buttonRoute ? (buttonRoute?.meta.title as string) : btn.title;
        btn.icon = !btn.icon && buttonRoute ? (buttonRoute?.meta.icon as string) : btn.icon;
        btn.minPermission =
          !btn.minPermission && buttonRoute ? (buttonRoute?.meta.minPermission as Permission) : btn.minPermission;
        if (btn.subButtons) addPathMetaInfo(btn.subButtons);
      });
    }

    addPathMetaInfo(topButtons);
    addPathMetaInfo(bottomButtons);

    return {
      bottomButtons,
      email,
      fullname,
      loggingOut,
      logout,
      permission,
      topButtons,
      PopupIcons,
      PopupButtons,
      PopupColors,
    };
  },
});
</script>

<style scoped lang="scss" src="./NavMenu.scss"></style>
