<template>
  <form class="verticalForm" :key="formKey">
    <ul v-for="inputRow in formInputs" :key="JSON.stringify(inputRow)" class="horizontalForm">
      <ul v-for="input in inputRow" :key="JSON.stringify(input)">
        <li
          v-if="
            inputPermission(input) &&
            input.type !== InputType.spacer &&
            input.field &&
            (!input.dependsOnInput || 
              currentObject[input.dependsOnInput.field as string] === input.dependsOnInput.value)
          "
          :class="input.type === InputType.checkbox ? 'horizontalInput' : 'verticalInput'"
        >
          <p v-if="input.required && !input.dontShowTitle">{{ $t(input.title ? input.title : input.field) + " *" }}</p>
          <p v-else-if="!input.dontShowTitle">{{ $t(input.title ? input.title : input.field) }}</p>
          <Checkbox
            v-if="input.type === InputType.checkbox"
            :value="currentObject[input.field]"
            :readonly="inputVisibility(input)"
            @newValue="(val) => inputChanged(input.field as string, val)"
          />
          <Textbox
            v-else-if="input.type === InputType.textbox || input.type === InputType.textarea"
            :textarea="input.type === InputType.textarea"
            :placeholder="input.placeHolder ? input.placeHolder : ''"
            :readonly="inputVisibility(input)"
            :value="currentObject[input.field]"
            @newValue="(val) => inputChanged(input.field as string, val)"
            @format="(val) => formatInputValue(input, val)"
          />
          <Jsonbox
            v-else-if="input.type === InputType.jsonbox"
            :readonly="inputVisibility(input)"
            :value="currentObject[input.field]"
            @newValue="(val) => inputChanged(input.field as string, val)"
          />
          <Dropdown
            v-else-if="'options' in input && input.type === InputType.dropdown"
            :dropdownType="DropdownTypes.formDropdown"
            :options="input.options"
            :parentSelectedOption="setSelectedDropdownOption(input)"
            @optionSelected="(option) => (option ? formatInputValue(input, option.value) : formatInputValue(input, ''))"
          />
          <p class="errorText" v-if="input.error">{{ $t(input.error ?? "") }}</p>
        </li>
        <div v-else-if="input.type === InputType.spacer"></div>
      </ul>
    </ul>
  </form>
</template>

<script lang="ts">
import { PropType, Ref, computed, defineComponent, onMounted, ref } from "vue";

import { Permission, useAuthStore } from "../../../store/auth";
import { AnyDict, DropdownFormInput, DropdownOption, FormInput } from "../../../types/general";
import { isNullOrUndefined } from "../../../utils/general";
import Dropdown, { DropdownTypes } from "../dropdown/dropdown.vue";
import Checkbox from "./checkbox/Checkbox.vue";
import Jsonbox from "./jsonbox/Jsonbox.vue";
import Textbox from "./textbox/Textbox.vue";

/* eslint-disable no-unused-vars */
export enum InputType {
  checkbox = 0,
  textbox = 1,
  dropdown = 2,
  spacer = 3,
  jsonbox = 4,
  textarea = 5,
}

export enum InputFieldType {
  bool = 0,
  int = 1,
  float = 2,
  string = 3,
  object = 4,
  array = 5,
}
/* eslint-enable */

export default defineComponent({
  name: "Form",
  components: { Checkbox, Textbox, Dropdown, Jsonbox },
  props: {
    parentObject: { required: true, type: Object },
    inputs: { required: true, type: Array as PropType<Array<Array<FormInput>>> },
  },
  emits: ["changed"],
  setup(props, { emit }) {
    const authStore = useAuthStore();

    const permission = authStore.permission as Permission;

    const formInputs = ref(props.inputs);

    let formattedObject = { ...props.parentObject };
    let currentObject = { ...props.parentObject };
    let originalObject = { ...props.parentObject };

    let formKey = ref(Math.random());

    const isValid = computed(
      () =>
        formInputs.value.find((inputRow: Array<FormInput>) =>
          inputRow.find((input) => input.error !== "" && input.error !== undefined)
        ) === undefined
    );

    const dependantFields = computed(() =>
      formInputs.value
        .flatMap((inputs) => inputs)
        .filter((input: FormInput) =>
          formInputs.value
            .flatMap((inputs) => inputs)
            .some((dependantInput: FormInput) => dependantInput.dependsOnInput?.field === input.field)
        )
    );

    function setFieldType(input: FormInput, object: AnyDict) {
      if (input.fieldType === undefined && input.field) {
        if (Array.isArray(object[input.field])) {
          input.fieldType = InputFieldType.array;
        } else if (typeof object[input.field] === "object") {
          input.fieldType = InputFieldType.object;
        } else if (typeof object[input.field] === "number") {
          input.fieldType = InputFieldType.float;
        } else {
          input.fieldType = InputFieldType.string;
        }
      }
    }

    function formatInputFields(inputs: Ref<Array<Array<FormInput>>>, object: AnyDict) {
      inputs.value.forEach((inputRow) =>
        inputRow.forEach((input) => {
          if (input.field) {
            input.error = "";
            setFieldType(input, object);
            if (!isNullOrUndefined(object[input.field])) {
              if (
                (input.fieldType === InputFieldType.int || input.fieldType === InputFieldType.float) &&
                !(input.type === InputType.dropdown)
              ) {
                currentObject[input.field] = object[input.field].toString();
              } else if (input.fieldType === InputFieldType.object || input.fieldType === InputFieldType.array) {
                currentObject[input.field] = object[input.field];
              } else {
                currentObject[input.field] = object[input.field];
              }
            } else if (!isNullOrUndefined(input.default)) {
              currentObject[input.field] = input.default;
              formattedObject[input.field] = input.default;
            } else if (input.fieldType === InputFieldType.bool) {
              currentObject[input.field] = false;
              formattedObject[input.field] = false;
            } else {
              currentObject[input.field] = undefined; //"";
              formattedObject[input.field] = undefined; //"";
            }

            if (input.type === InputType.dropdown && "options" in input) {
              const selectedOption = input.options.find((o: DropdownOption) => o.selected) as DropdownOption;
              if (!input.required) {
                let emptyOption = (input as DropdownFormInput).options.find(
                  (o: DropdownOption) => o.label.trim() === ""
                ) as DropdownOption;
                if (!emptyOption) {
                  emptyOption = {
                    label: "",
                    value: formattedObject[input.field],
                  };
                }
                if (!selectedOption) {
                  emptyOption.selected = true;
                }
                (input as DropdownFormInput).options.unshift(emptyOption);
              } else if (!selectedOption) {
                if (input.default) {
                  const defaultOption = input.options.find((o: DropdownOption) => o.value === input.default);
                  if (defaultOption) defaultOption.selected = true;
                }
              }
            }
          }
        })
      );
    }

    function formatInputValue(input: FormInput, val: string) {
      if (
        input.field &&
        (!input.dependsOnInput || currentObject[input.dependsOnInput.field as string] === input.dependsOnInput.value)
      ) {
        let parsed_value: number | string | object;
        if (input.type === InputType.dropdown && "options" in input) {
          currentObject[input.field] = val;
          const selectedOption = input.options.find((option) => option && option.value === val);
          if ((!selectedOption || selectedOption.label === "") && input.required) {
            input.error = "invalidDropdownValue";
          } else {
            (selectedOption as DropdownOption).selected = true;
            formattedObject[input.field] = val;
            input.error = "";
          }
        } else if (input.required && (val === "" || isNullOrUndefined(val))) {
          input.error = "emptyRequiredEntry";
        } else if (
          !input.required &&
          (val === "" || isNullOrUndefined(val)) &&
          input.fieldType !== InputFieldType.string
        ) {
          input.error = "";
          formattedObject[input.field] = null;
        } else if (input.error === "") {
          switch (input.fieldType) {
            case InputFieldType.int:
              parsed_value = Number(val);
              if (!isNaN(parsed_value) && Number.isInteger(parsed_value)) {
                formattedObject[input.field] = parsed_value;
                input.error = "";
              } else {
                input.error = "intInvalidEntry";
              }
              break;
            case InputFieldType.float:
              parsed_value = parseFloat(val);
              if (!isNaN(parsed_value)) {
                formattedObject[input.field] = parsed_value;
                input.error = "";
              } else {
                input.error = "floatInvalidEntry";
              }
              break;
            case InputFieldType.object:
            case InputFieldType.array:
              try {
                if (typeof val === "string") val = JSON.parse(val);
                formattedObject[input.field] = val;
                input.error = "";
              } catch (e) {
                if (input.type === InputType.jsonbox) {
                  // Temporary fix for formatting of json boxes creating json strings
                  formattedObject[input.field] = originalObject[input.field];
                  currentObject[input.field] = originalObject[input.field];
                  input.error = "jsonboxInvalidEntry";
                } else {
                  input.error = input.fieldType === InputFieldType.object ? "objectInvalidEntry" : "arrayInvalidEntry";
                }
              }
              break;
            default:
              formattedObject[input.field] = val;
          }
        } else {
          input.error = "";
        }
        emit("changed", JSON.stringify(originalObject) !== JSON.stringify(currentObject));
      }
    }

    function validateForm() {
      formInputs.value.forEach((inputRow) =>
        inputRow.forEach((input) => {
          if (input.field) {
            if (
              input.required &&
              (currentObject[input.field] === "" || currentObject[input.field] === undefined) &&
              (!input.dependsOnInput ||
                currentObject[input.dependsOnInput.field as string] === input.dependsOnInput.value)
            ) {
              input.error = "emptyRequiredEntry";
            } else {
              formatInputValue(input, currentObject[input.field]);
            }
          }
        })
      );
    }

    formatInputFields(formInputs, formattedObject);

    function inputChanged(field: string, value: string | boolean) {
      currentObject[field] = value;
      if (dependantFields.value.some((input: FormInput) => input.field === field)) {
        formKey.value = Math.random();
      }

      if (typeof value === "boolean") {
        formattedObject[field] = value;
      } else if (typeof originalObject[field] === "object" && typeof value === "string") {
        try {
          if (value !== "") {
            currentObject[field] = JSON.parse(value);
          } else {
            currentObject[field] = undefined;
          }
        } catch (e) {
          currentObject[field] = value;
        }
      }
      emit("changed", JSON.stringify(originalObject) !== JSON.stringify(currentObject));
    }

    function inputVisibility(input: FormInput) {
      return (
        input.readonly ||
        (input.readLevel && input.editLevel && input.readLevel >= permission && input.editLevel < permission)
      );
    }

    function inputPermission(input: FormInput) {
      return !input.readLevel || permission >= input.readLevel;
    }

    function setSelectedDropdownOption(input: DropdownFormInput) {
      let selectedOption = input.options.find(
        (o: DropdownOption) => o.value === currentObject[input.field as string]
      ) as DropdownOption;
      if (selectedOption) return selectedOption;
      else selectedOption = input.options.find((o: DropdownOption) => o.selected) as DropdownOption;
      return selectedOption;
    }

    function revertChanges() {
      formatInputFields(formInputs, originalObject);
      formKey.value = Math.random();
      emit("changed", JSON.stringify(originalObject) !== JSON.stringify(currentObject));
    }

    // Fixes a bug where the dropdown would set the changed state
    onMounted(() => {
      originalObject = { ...currentObject };
      emit("changed", JSON.stringify(originalObject) !== JSON.stringify(currentObject));
    });

    return {
      currentObject,
      inputChanged,
      formatInputValue,
      formattedObject,
      permission,
      inputVisibility,
      inputPermission,
      InputType,
      DropdownTypes,
      originalObject,
      validateForm,
      isValid,
      formInputs,
      setSelectedDropdownOption,
      revertChanges,
      formKey,
    };
  },
});
</script>

<style scoped lang="scss" src="./Form.scss"></style>
