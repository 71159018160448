import { defineStore } from "pinia";

import { InputFieldType, InputType } from "../components/basicParts/form/Form.vue";
import httpCommon from "../plugins/http-common";
import { AnyDict, ConfigurationGroup, FormInput, JsonTable, Module, TabButton } from "../types/general";
import { Permission } from "./auth";

interface State {
  currentRobotConfigs: Array<ConfigurationGroup>;
  currentRobotModules: Array<Module>;
  currentTabs: Array<TabButton>;
  selectedTabKey: string;
  moduleInputs: Array<Array<FormInput>>;
  modules: Array<Module>;
  features: JsonTable;
}

export const useConfigStore = defineStore("config", {
  state: (): State => {
    return {
      currentRobotConfigs: [],
      currentRobotModules: [],
      currentTabs: [],
      selectedTabKey: "",
      moduleInputs: [],
      modules: [],
      features: {},
    };
  },
  getters: {
    featuresInputs: () => [
      [
        {
          field: "value",
          fieldType: InputFieldType.object,
          dontShowTitle: true,
          type: InputType.jsonbox,
          readLevel: Permission.User,
          editLevel: Permission.User,
        },
      ],
    ],
  },
  actions: {
    async loadConfigs(robot_id: number) {
      await this.getRobotConfigs(robot_id);
      await this.generateModuleInputs();
    },
    async getRobotConfigs(robot_id: number) {
      const res = await httpCommon.get("configs/ui/configs/" + robot_id);
      this.currentRobotConfigs = res.data;
      await this.getRobotModules(robot_id);
      this.generateCurrentTabs();
      return res.data;
    },
    async patchRobotModules(robot_id: number, modules: Array<Module>) {
      const res = await httpCommon.patch("configs/ui/modules/" + robot_id, { modules: modules });
      return res.data;
    },
    async getRobotModules(robot_id: number) {
      const res = await httpCommon.get("configs/ui/modules/" + robot_id);
      this.currentRobotModules = res.data;
      return res.data;
    },
    async patchRobotConfigGroupValues(configGroupId: number, configValues: AnyDict, uuid: string | undefined) {
      const res = await httpCommon.patch("configs/ui/config/group/" + configGroupId + "/" + uuid, configValues);
      return res.data;
    },
    async getRobotFeatures(robot_id: number) {
      const res = await httpCommon.get("configs/ui/features/" + robot_id);
      this.features = res.data;
      return res.data;
    },
    async patchRobotFeatures(robot_id: number, features: AnyDict) {
      const res = await httpCommon.patch("configs/ui/features/" + robot_id, features);
      return res.data;
    },
    generateCurrentTabs() {
      this.currentTabs = [] as Array<TabButton>;
      const keys = [] as Array<string>;
      if (this.selectedTabKey === "") {
        this.selectedTabKey = "general";
      }
      if (this.currentRobotModules) {
        this.currentTabs.push({
          key: "modules",
          selected: false,
        });
      }

      if (this.currentRobotConfigs) {
        this.currentTabs.push(
          ...(this.currentRobotConfigs
            .map((configGroup) => {
              if (configGroup.type) {
                const key = configGroup.type?.ui_tab_key?.toLowerCase();
                if (key && !keys.includes(key)) {
                  keys.push(key);
                  return { key, selected: this.selectedTabKey === key };
                }
              }
              return null;
            })
            .filter(Boolean)
            .sort((a, b) => ((a as TabButton).key > (b as TabButton).key ? 1 : -1)) as Array<TabButton>)
        );
      }

      if (!this.currentTabs.find((tab) => tab && tab.selected) && this.currentTabs[0]) {
        this.currentTabs[0].selected = true;
      }
    },
    async generateModuleInputs() {
      const res = await httpCommon.get("configs/ui/modules");
      if (res) {
        this.moduleInputs = [];
        this.modules = res.data;

        let subInputs = [] as Array<FormInput>;
        let i = 0;
        this.modules.forEach((module: Module) => {
          subInputs.push({
            field: module.name,
            title: module.name as string,
            type: InputType.checkbox,
            fieldType: InputFieldType.bool,
          } as FormInput);
          i++;
          if (i % 2 === 0 || i === this.modules.length) {
            if (i % 2 === 1 || i === this.modules.length) {
              subInputs.push({
                type: InputType.spacer,
              });
            }
            this.moduleInputs.push(subInputs);
            subInputs = [];
          }
        });
      }
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
