import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JsonEditorVue = _resolveComponent("JsonEditorVue")!

  return (_openBlock(), _createBlock(_component_JsonEditorVue, {
    class: "jsonContainer",
    modelValue: _ctx.localValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
    mode: "text",
    readOnly: _ctx.readonly,
    mainMenuBar: false,
    oninput: (newVal) => _ctx.$emit('newValue', newVal)
  }, null, 8, ["modelValue", "readOnly", "oninput"]))
}