<template>
  <JsonEditorVue
    class="jsonContainer"
    v-model="localValue"
    mode="text"
    :readOnly="readonly"
    :mainMenuBar="false"
    :oninput="(newVal: string) => $emit('newValue', newVal)"
  />
</template>

<script lang="ts">
import JsonEditorVue from "json-editor-vue";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "Jsonbox",
  components: {
    JsonEditorVue,
  },
  props: {
    readonly: { required: false, type: Boolean },
    value: { required: false, type: [String, Array, Object, Number] },
  },
  setup(props, { emit }) {
    const localValue = ref(props.value);

    watch(localValue, (newVal) => {
      emit("newValue", newVal);
    });

    return { localValue };
  },
});
</script>

<style scoped lang="scss" src="./Jsonbox.scss"></style>
