import { defineStore } from "pinia";

import httpCommon from "../plugins/http-common";
import { useClientStore } from "./client";

/* eslint-disable no-unused-vars */
export enum Permission {
  User = 1,
  SuperUser = 2,
  Tech = 3,
  Admin = 4,
}
/* eslint-enable */
interface State {
  fullname: string;
  email: string;
  token: string | null;
  permission: Permission | null;
}
interface UserLogin {
  email: string;
  password: string;
}

function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const useAuthStore = defineStore("auth", {
  state: (): State => {
    return {
      fullname: "",
      email: "",
      token: "",
      permission: null,
    };
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    isAdmin: (state) => state.permission === Permission.Admin,
    isTech: (state) => state.permission && state.permission >= Permission.Tech,
    getPermission: (state) => state.permission,
    StateToken: (state) => state.token,
  },
  actions: {
    async login(data: UserLogin) {
      try {
        const res = await httpCommon.post("auth/login", data);
        if (res && !res.data.error) {
          const data = parseJwt(res.data.access_token);
          localStorage.setItem("token", data.token);
          this.permission = data.permission_level as number;
          this.fullname = data.fullname;
          this.email = data.email;
          this.token = res.data.access_token;
          if (data.clients && data.clients.length === 1) {
            const clientStore = useClientStore();
            clientStore.currentClient = await clientStore.fetchClient(data.clients[0].id);
          }
          return true;
        }
      } catch (error) {
        return false;
      }
      return false;
    },
    logout() {
      localStorage.removeItem("token");
      this.permission = null;
      this.fullname = "";
      this.email = "";
      this.token = "";
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
