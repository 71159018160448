import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-24c68214"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 6,
  class: "errorText"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Textbox = _resolveComponent("Textbox")!
  const _component_Jsonbox = _resolveComponent("Jsonbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("form", {
    class: "verticalForm",
    key: _ctx.formKey
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formInputs, (inputRow) => {
      return (_openBlock(), _createElementBlock("ul", {
        key: JSON.stringify(inputRow),
        class: "horizontalForm"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(inputRow, (input) => {
          return (_openBlock(), _createElementBlock("ul", {
            key: JSON.stringify(input)
          }, [
            (
            _ctx.inputPermission(input) &&
            input.type !== _ctx.InputType.spacer &&
            input.field &&
            (!input.dependsOnInput || 
              _ctx.currentObject[input.dependsOnInput.field as string] === input.dependsOnInput.value)
          )
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: _normalizeClass(input.type === _ctx.InputType.checkbox ? 'horizontalInput' : 'verticalInput')
                }, [
                  (input.required && !input.dontShowTitle)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t(input.title ? input.title : input.field) + " *"), 1))
                    : (!input.dontShowTitle)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t(input.title ? input.title : input.field)), 1))
                      : _createCommentVNode("", true),
                  (input.type === _ctx.InputType.checkbox)
                    ? (_openBlock(), _createBlock(_component_Checkbox, {
                        key: 2,
                        value: _ctx.currentObject[input.field],
                        readonly: _ctx.inputVisibility(input),
                        onNewValue: (val) => _ctx.inputChanged(input.field as string, val)
                      }, null, 8, ["value", "readonly", "onNewValue"]))
                    : (input.type === _ctx.InputType.textbox || input.type === _ctx.InputType.textarea)
                      ? (_openBlock(), _createBlock(_component_Textbox, {
                          key: 3,
                          textarea: input.type === _ctx.InputType.textarea,
                          placeholder: input.placeHolder ? input.placeHolder : '',
                          readonly: _ctx.inputVisibility(input),
                          value: _ctx.currentObject[input.field],
                          onNewValue: (val) => _ctx.inputChanged(input.field as string, val),
                          onFormat: (val) => _ctx.formatInputValue(input, val)
                        }, null, 8, ["textarea", "placeholder", "readonly", "value", "onNewValue", "onFormat"]))
                      : (input.type === _ctx.InputType.jsonbox)
                        ? (_openBlock(), _createBlock(_component_Jsonbox, {
                            key: 4,
                            readonly: _ctx.inputVisibility(input),
                            value: _ctx.currentObject[input.field],
                            onNewValue: (val) => _ctx.inputChanged(input.field as string, val)
                          }, null, 8, ["readonly", "value", "onNewValue"]))
                        : ('options' in input && input.type === _ctx.InputType.dropdown)
                          ? (_openBlock(), _createBlock(_component_Dropdown, {
                              key: 5,
                              dropdownType: _ctx.DropdownTypes.formDropdown,
                              options: input.options,
                              parentSelectedOption: _ctx.setSelectedDropdownOption(input),
                              onOptionSelected: (option) => (option ? _ctx.formatInputValue(input, option.value) : _ctx.formatInputValue(input, ''))
                            }, null, 8, ["dropdownType", "options", "parentSelectedOption", "onOptionSelected"]))
                          : _createCommentVNode("", true),
                  (input.error)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(input.error ?? "")), 1))
                    : _createCommentVNode("", true)
                ], 2))
              : (input.type === _ctx.InputType.spacer)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}