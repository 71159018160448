export function isEmptyObject(obj: unknown) {
  return obj && typeof obj === "object" && Object.keys(obj).length === 0;
}

export function isEmptyArray(obj: unknown) {
  return obj && Array.isArray(obj) && obj.length === 0;
}

export function isNullOrUndefined(obj: unknown) {
  return obj === null || obj === undefined;
}

export function isEmpty(obj: unknown) {
  return isNullOrUndefined(obj) || isEmptyArray(obj) || isEmptyObject(obj);
}
