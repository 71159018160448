import moment from "moment-timezone";
import { defineStore } from "pinia";

import { InputFieldType, InputType } from "../components/basicParts/form/Form.vue";
import httpCommon from "../plugins/http-common";
import { Fleet, Robot } from "../types/general";
import { FormInput } from "../types/general";
import { Permission } from "./auth";
import { useClientStore } from "./client";
import { useConfigStore } from "./config";
import { useJobStore } from "./job";
import { useMapStore } from "./map";
import { useStatesStore } from "./states";

interface State {
  clientFleets: Fleet[];
  currentRobot: Robot | null;
  robotSelectionRedirect: boolean;
}

/* eslint-disable no-unused-vars */
export enum RobotState {
  INIT = "INIT",
  ESTOP = "ESTOP",
  LOST = "LOST",
  ERP = "ERP",
  VISIT = "VISIT",
  CHARGING = "CHARGING",
  DEMO = "DEMO",
  ACTIVE = "ACTIVE",
  IDLE = "IDLE",
}

// TODO validate when jobs states well are defined
export enum JobState {
  RUNNING = "RUNNING",
  PAUSED = "PAUSED",
}
/* eslint-enable */

export const useRobotStore = defineStore("robot", {
  state: (): State => {
    return {
      clientFleets: [],
      currentRobot: null,
      robotSelectionRedirect: false,
    };
  },
  getters: {
    robotFormInputs: () => {
      const allTimezones = moment.tz.names();

      const timezones = allTimezones.map((timezone) => {
        return { label: timezone, value: timezone };
      });

      return [
        [
          {
            field: "name",
            title: "name",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
            required: true,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "timezone",
            fieldType: InputFieldType.string,
            title: "timezone",
            required: true,
            type: InputType.dropdown,
            options: timezones,
          },
        ],
        [
          {
            field: "latitude",
            title: "latitude",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
          },
          {
            field: "longitude",
            title: "longitude",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
          },
        ],
        [
          {
            field: "address_1",
            title: "address1",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
          },
        ],
        [
          {
            field: "address_2",
            title: "address2",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
          },
          {
            field: "postal_code",
            title: "postalCode",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
          },
        ],
        [
          {
            field: "city",
            title: "city",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
          },
        ],
        [
          {
            field: "region",
            title: "region",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
          },
          {
            field: "country",
            title: "country",
            type: InputType.textbox,
            fieldType: InputFieldType.string,
          },
        ],
        [
          {
            field: "description",
            title: "description",
            type: InputType.textarea,
            fieldType: InputFieldType.string,
          },
        ],
        [
          {
            field: "uuid",
            title: "uuid",
            type: InputType.textbox,
            readonly: true,
            fieldType: InputFieldType.string,
          },
        ],
      ] as Array<Array<FormInput>>;
    },
  },
  actions: {
    toggleCurrentRobot(robot: Robot | null, selected: boolean) {
      useConfigStore().$reset();
      useJobStore().$reset();
      useMapStore().$reset();
      useStatesStore().$reset();
      this.currentRobot = selected ? robot : null;
    },
    async fetchCurrentClientFleets() {
      const clientStore = useClientStore();
      if (clientStore.currentClient?.id) {
        const res = await httpCommon.get("fleets/client/" + clientStore.currentClient?.id);
        if (res && !res.data.error) {
          this.clientFleets = res.data;
          return res.data;
        }
      }
      return undefined;
    },
    async patchFleet(fleet: Fleet) {
      const res = await httpCommon.patch("fleets/" + fleet.id, fleet);
      if (res && !res.data.error) {
        return res.data;
      }
      return {};
    },
    async postFleet(fleet: Fleet) {
      const res = await httpCommon.post("fleets/", fleet);
      if (res && !res.data.error) {
        return res.data as Fleet;
      }
      return {};
    },
    async deleteFleet(id: number) {
      const res = await httpCommon.delete("fleets/" + id);
      if (res && !res.data.error) {
        return true;
      }
      return false;
    },
    async fetchRobot(id: number) {
      const res = await httpCommon.get("robots/" + id);
      if (res && !res.data.error) {
        this.currentRobot = res.data;
        return res.data as Robot;
      }
      return {};
    },
    async postRobot(robot: Robot) {
      const res = await httpCommon.post("robots/", robot);
      if (res && !res.data.error) {
        return res.data;
      }
      return {};
    },
    async patchRobot(robot: Robot) {
      const res = await httpCommon.patch("robots/", robot);
      if (res && !res.data.error) {
        return res.data;
      }
      return {};
    },
    async deleteRobot(id: number) {
      const res = await httpCommon.delete("robots/" + id);
      if (res && !res.data.error) {
        if (this.currentRobot && id === this.currentRobot.id) {
          this.currentRobot = null;
        }
        return true;
      }
      return false;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
