import { defineStore } from "pinia";

import { InputFieldType, InputType } from "../components/basicParts/form/Form.vue";
import httpCommon from "../plugins/http-common";
import { FormInput, StateDetail, States } from "../types/general";
import { Permission } from "./auth";

interface State {
  states: States | undefined;
}

export const useStatesStore = defineStore("states", {
  state: (): State => {
    return {
      states: undefined,
    };
  },
  getters: {
    stateDetailArray: (state) => {
      const states: States = state.states ?? useStatesStore().emptyStates;
      return Object.entries(states).filter(
        ([key]) => key.includes("_state") && !key.includes("_id") && key !== "id"
      ) as Array<[string, StateDetail]>;
    },
    emptyStates: () =>
      ({
        id: undefined,
        init_state_detail: undefined,
        estop_state_detail: undefined,
        lost_state_detail: undefined,
        demo_state_detail: undefined,
        erp_state_detail: undefined,
        visit_state_detail: undefined,
        charging_state_detail: undefined,
        active_state_detail: undefined,
        idle_state_detail: undefined,
      } as States),
    stateDetailInputs: () =>
      [
        [
          {
            field: "led",
            fieldType: InputFieldType.object,
            title: "led",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "activation_delay",
            fieldType: InputFieldType.int,
            title: "activationDelay",
            type: InputType.textbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "begin_delay",
            fieldType: InputFieldType.int,
            title: "beginDelay",
            type: InputType.textbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "ui_display",
            fieldType: InputFieldType.object,
            title: "uiDisplay",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "activation_informing_services",
            fieldType: InputFieldType.object,
            title: "activationInformingServices",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "deactivation_informing_services",
            fieldType: InputFieldType.object,
            title: "deactivationInformingServices",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
      ] as Array<Array<FormInput>>,
  },
  actions: {
    async fetchStates(robot_id: number) {
      const res = await httpCommon.get("states/ui/" + robot_id);
      this.states = res.data;
      return res.data;
    },
    async patchStateDetail(stateDetail: StateDetail, type: string, robot_id: number) {
      const res = await httpCommon.patch("states/ui/detail/" + type + "/" + robot_id, stateDetail);
      return res.data;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
