import { defineStore } from "pinia";

import { InputFieldType, InputType } from "../components/basicParts/form/Form.vue";
import httpCommon from "../plugins/http-common";
import { Action, FormInput, Job, Route, Schedule, ScheduleItem, Task } from "../types/general";
import { Permission } from "./auth";

interface State {
  currentRobotActions: Array<Action>;
  currentRobotTasks: Array<Task>;
  currentRobotRoutes: Array<Route>;
  currentRobotSchedule: Schedule;
}

export const useJobStore = defineStore("job", {
  state: (): State => {
    return {
      currentRobotActions: [],
      currentRobotTasks: [],
      currentRobotRoutes: [],
      currentRobotSchedule: {},
    };
  },
  getters: {
    scheduleInputs(state) {
      let jobOptions = state.currentRobotActions.map((job: Action) => ({ label: job.name, value: job.id }));
      jobOptions = [...state.currentRobotTasks.map((job: Task) => ({ label: job.name, value: job.id })), ...jobOptions];
      jobOptions = [
        ...state.currentRobotRoutes.map((job: Route) => ({ label: job.name, value: job.id })),
        ...jobOptions,
      ];
      return [
        [
          {
            field: "job_id",
            fieldType: InputFieldType.int,
            title: "jobId",
            type: InputType.dropdown,
            options: jobOptions,
            required: true,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "recurrent",
            fieldType: InputFieldType.bool,
            title: "recurrent",
            default: true,
            type: InputType.checkbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "day",
            fieldType: InputFieldType.int,
            title: "day",
            dependsOnInput: {
              field: "recurrent",
              value: true,
            },
            required: true,
            type: InputType.dropdown,
            options: [
              { label: "sunday", value: 0 },
              { label: "monday", value: 1 },
              { label: "tuesday", value: 2 },
              { label: "wednesday", value: 3 },
              { label: "thursday", value: 4 },
              { label: "friday", value: 5 },
              { label: "saturday", value: 6 },
            ],
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "date",
            fieldType: InputFieldType.string,
            title: "date",
            dependsOnInput: {
              field: "recurrent",
              value: false,
            },
            required: true,
            type: InputType.textbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
            placeHolder: "YYYY-MM-DD",
          },
        ],
        [
          {
            field: "start_time",
            fieldType: InputFieldType.string,
            title: "startTime",
            required: true,
            type: InputType.textbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
            placeHolder: "HH:MM:SS",
          },
          {
            field: "end_time",
            fieldType: InputFieldType.string,
            title: "endTime",
            required: true,
            type: InputType.textbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
            placeHolder: "HH:MM:SS",
          },
        ],
        [
          {
            field: "infinite",
            fieldType: InputFieldType.bool,
            title: "infinite",
            type: InputType.checkbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "repeat_times",
            fieldType: InputFieldType.int,
            title: "repeatTimes",
            required: true,
            type: InputType.textbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
            dependsOnInput: {
              field: "infinite",
              value: false,
            },
          },
        ],
        [
          {
            field: "stop_on_clear",
            fieldType: InputFieldType.bool,
            title: "stopOnClear",
            type: InputType.checkbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
      ] as Array<Array<FormInput>>;
    },
    baseInputs: () =>
      [
        [
          {
            field: "name",
            fieldType: InputFieldType.string,
            title: "name",
            required: true,
            type: InputType.textbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "ui_display",
            fieldType: InputFieldType.object,
            title: "uiDisplay",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "starting_conditions",
            fieldType: InputFieldType.object,
            title: "startingConditions",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "ending_conditions",
            fieldType: InputFieldType.object,
            title: "endingConditions",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
      ] as Array<Array<FormInput>>,
    routeAndTaskInputs: () =>
      [
        [
          {
            field: "led",
            fieldType: InputFieldType.object,
            title: "led",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "success_conditions",
            fieldType: InputFieldType.object,
            title: "successConditions",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "on_start",
            fieldType: InputFieldType.object,
            title: "onStart",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "on_success",
            fieldType: InputFieldType.object,
            title: "onSuccess",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "on_failure",
            fieldType: InputFieldType.object,
            title: "onFailure",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
      ] as Array<Array<FormInput>>,

    actionInputs() {
      const baseInputs = this.baseInputs as Array<Array<FormInput>>;
      const inputs = [...baseInputs];
      inputs.push([
        {
          field: "behaviour",
          fieldType: InputFieldType.object,
          title: "behaviour",
          type: InputType.jsonbox,
          readLevel: Permission.User,
          editLevel: Permission.User,
        },
      ]);
      return inputs;
    },
    taskInputs() {
      const baseInputs = this.baseInputs as Array<Array<FormInput>>;
      const inputs = [...baseInputs];
      inputs.push(...this.routeAndTaskInputs);
      inputs.push([
        {
          field: "action_keys",
          fieldType: InputFieldType.array,
          title: "actionKeys",
          type: InputType.jsonbox,
          readLevel: Permission.User,
          editLevel: Permission.User,
        },
      ]);
      return inputs;
    },
    routeInputs() {
      const baseInputs = this.baseInputs as Array<Array<FormInput>>;
      const inputs = [...baseInputs];
      inputs.push(...this.routeAndTaskInputs);
      inputs.push(
        [
          {
            field: "restart_required",
            fieldType: InputFieldType.bool,
            title: "restartRequired",
            type: InputType.checkbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ],
        [
          {
            field: "task_keys",
            fieldType: InputFieldType.array,
            title: "taskKeys",
            type: InputType.jsonbox,
            readLevel: Permission.User,
            editLevel: Permission.User,
          },
        ]
      );
      return inputs;
    },
  },
  actions: {
    async fetchSchedule(robot_id: number) {
      const res = await httpCommon.get("schedules/ui/" + robot_id);
      if (res.data) {
        this.currentRobotSchedule = res.data;
      }
    },
    async patchScheduleItem(schedule_item: ScheduleItem) {
      await httpCommon.patch("schedules/ui/" + this.currentRobotSchedule.id, schedule_item);
    },
    async deleteScheduleItem(item_id: number, robot_uuid: string) {
      await httpCommon.delete("schedules/ui/" + item_id + "/" + robot_uuid);
    },
    async fetchJobs(robot_id: number) {
      const res = await httpCommon.get("jobs/ui/" + robot_id);
      if (res.data) {
        this.currentRobotActions = res.data.filter((job: Job) => job.job_type === "Action") || [];
        this.currentRobotTasks.values = res.data.filter((job: Job) => job.job_type === "Task") || [];
        this.currentRobotRoutes = res.data.filter((job: Job) => job.job_type === "Route") || [];
      }
    },
    async fetchActions(robot_id: number) {
      const res = await httpCommon.get("jobs/ui/actions/" + robot_id);
      this.currentRobotActions = res.data || [];
      return res.data;
    },
    async postAction(action: Action, robot_id: number) {
      await httpCommon.post("jobs/ui/action/" + robot_id, action);
    },
    async patchAction(action: Action, robot_uuid: string) {
      await httpCommon.patch("jobs/ui/action/" + robot_uuid, action);
    },
    async deleteAction(id: number, robot_uuid: string) {
      await httpCommon.delete("jobs/ui/action/" + id + "/" + robot_uuid);
    },
    async fetchTasks(robot_id: number) {
      const res = await httpCommon.get("jobs/ui/tasks/" + robot_id);
      this.currentRobotTasks = res.data ? res.data : [];
      if (res.data) {
        res.data.forEach((task: Task) => {
          task.action_keys = task.actions?.sort((action: Action) => action.order).map((action: Action) => action.key);
        });
      }
      return res.data;
    },
    async postTask(task: Task, robot_id: number) {
      await httpCommon.post("jobs/ui/task/" + robot_id, task);
    },
    async patchTask(task: Task, robot_uuid: string) {
      await httpCommon.patch("jobs/ui/task/" + robot_uuid, task);
    },
    async deleteTask(id: number, robot_uuid: string) {
      await httpCommon.delete("jobs/ui/task/" + id + "/" + robot_uuid);
    },
    async fetchRoutes(robot_id: number) {
      const res = await httpCommon.get("jobs/ui/routes/" + robot_id);
      this.currentRobotRoutes = res.data || [];
      if (res.data) {
        res.data.forEach((route: Route) => {
          let subJobKeyOrders: { key: string; order: number }[] =
            route.tasks?.map((task: Task) => ({
              key: task.key,
              order: task.order,
            })) ?? [];
          subJobKeyOrders = subJobKeyOrders.concat(
            route.actions?.map((action: Action) => ({
              key: action.key,
              order: action.order,
            })) ?? []
          );
          route.task_keys = subJobKeyOrders
            .sort((a: { key: string; order: number }, b: { key: string; order: number }) => a.order - b.order)
            .map((item: { key: string; order: number }) => item.key);
        });
      }
      return res.data;
    },
    async postRoute(route: Route, robot_id: number) {
      await httpCommon.post("jobs/ui/route/" + robot_id, route);
    },
    async patchRoute(route: Route, robot_uuid: string) {
      await httpCommon.patch("jobs/ui/route/" + robot_uuid, route);
    },
    async deleteRoute(id: number, robot_uuid: string) {
      await httpCommon.delete("jobs/ui/route/" + id + "/" + robot_uuid);
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
